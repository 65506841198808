<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Profil PPB</h3>
        </div>
      </div>
      <div class="card-body">
        <!--begin::Example-->
        <div class="example mb-10">
          <h4 class="mb-10 font-weight-bold text-dark">
            INFORMASI IDENTITAS PPB
          </h4>
          <!--begin::Input-->
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Provinsi/Kabupaten/Kota</label
            >
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.daerah"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Nomor Surat Keputusan Pembentukan Tim</label
            >
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.surat_penugasan"
                class="form-control"
              />
            </div>
          </div>
          <!-- <div class="form-group row">
                    <label class="col-4">Nomenklatur Sesuai Permendagri No. 25 Tahun 2021</label>
                        <div class="col-3">
                            <b-form-select v-model="profil.nomenklatur_permendagri" :options="permendagri" style="width: 150px;"></b-form-select>
                        </div>
                </div> -->
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Ketua Tim</label>
            <div class="col-lg-8">
              <input
                type="text"
                disabled
                v-model="profil.ketua"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Anggota</label>
            <div class="col-lg-8">
              <b-form-tags
                input-id="tags-basic"
                v-model="profil.anggota_array"
              ></b-form-tags>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Alamat Kantor</label>
            <div class="col-lg-8">
              <input
                disabled
                type="text"
                v-model="profil.alamat"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Nomor telepon/faksimili kantor/sekretariat</label
            >
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-6">
                  <!--begin::Input-->
                  <div class="form-group">
                    <input
                      type="number"
                      disabled
                      v-model="profil.telepon"
                      class="form-control"
                    />
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-6">
                  <!--begin::Input-->
                  <div class="form-group">
                    <input
                      type="number"
                      disabled
                      v-model="profil.faksimili"
                      class="form-control"
                    />
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"
              >Alamat surat elektronik kantor/sekretariat</label
            >
            <div class="col-lg-8">
              <input
                type="email"
                disabled
                v-model="profil.email"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Nama narahubung</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-avatar"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        disabled
                        v-model="profil.nama_contact_person1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon2-phone"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        disabled
                        v-model="profil.no_contact_person1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-email"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        disabled
                        v-model="profil.email_contact_person1"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-avatar"></i>
                        </span>
                      </div>
                      <input
                        type="text"
                        disabled
                        v-model="profil.nama_contact_person2"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon2-phone"></i>
                        </span>
                      </div>
                      <input
                        type="number"
                        disabled
                        v-model="profil.no_contact_person2"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <i class="flaticon-email"></i>
                        </span>
                      </div>
                      <input
                        type="email"
                        disabled
                        v-model="profil.email_contact_person2"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label">Titik Koordinat</label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <b>latitude</b>
                        </span>
                      </div>
                      <input
                        type="text"
                        disabled
                        v-model="profil.latitude"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
                <div class="col-xl-4">
                  <!--begin::Input-->
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">
                          <b>longitude</b>
                        </span>
                      </div>
                      <input
                        type="text"
                        disabled
                        v-model="profil.longtitude"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <!--end::Input-->
                </div>
              </div>
              <!-- <button @click="update" style="float: right;" class="btn btn-primary font-weight-bolder text-uppercase px-9 py-4">Save</button> -->
            </div>
          </div>
          <div class="form-group row">
            <label class="col-lg-4 col-form-label"></label>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-xl-12">
                  <!--begin::Input-->
                  <div class="form-group">
                    <label>Foto Tampak Depan Kantor</label>
                    <div></div>
                  </div>
                  <b-img
                    :src="profil.tampak_depan"
                    style="height: 100px"
                    fluid
                    alt="Foto"
                  ></b-img>
                  <!--end::Input-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Example-->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Swal from "sweetalert2";
// @import "vue-select/src/scss/vue-select.scss";

export default {
  components: { vSelect },
  data() {
    return {
      urlpenilaian: localStorage.getItem("baseapipenilaian"),
      idprofil: localStorage.getItem("idprofil"),
      user: JSON.parse(localStorage.getItem("user")),
      tanggal: localStorage.getItem("tanggal"),
      status: [],
      //   tabIndex: 0
      idptsp: "",
      daerah: "",
      nomenklatur_ptsp: "",
      tahun_ptsp: "",
      alamat: "",
      kodepos: "",
      nofax: "",
      website: "",
      email: "",
      facebook: "",
      instagram: "",
      twitter: "",
      nama_kepala: "",
      nama_contact_person1: "",
      no_contact_person1: "",
      email_contact_person1: "",
      nama_contact_person2: "",
      no_contact_person2: "",
      longtitude: "",
      latitude: "",
      longitude: "",
      notelp: "",
      perizinan_dilayani: "",
      aktifitas_dilayani: "",
      sdm_pelayanan: "",
      sdm_dalaks: "",
      email_contact_person1: "",
      email_contact_person2: "",
      tampak_depan: "",
      selectedpermendagri: null,
      permendagri: [
        { value: null, text: "Silahkan Pilih" },
        { value: "Sesuai", text: "Sesuai" },
        { value: "Belum sesuai", text: "Belum sesuai" },
      ],
      selectedprovinsi: "",
      provinsi: [
        {
          id: "",
          name: "",
        },
      ],
      selectedkota: "",
      kota: [
        {
          id: "",
          name: "",
        },
      ],
      toastCount: 0,
      profil: [],
      value: ["apple", "orange"],
    };
  },
  //   computed: {
  //     ...mapGetters(["layoutConfig"]),

  //     config() {
  //       return this.layoutConfig();
  //     }
  //   },
  mounted() {
    // set the tab from previous
    // this.setActivePreviousTab();

    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profil PPB" }]);
  },
  methods: {
    loaddata() {
      axios
        .get(
          this.urlpenilaian +
            `/profil_ppb_pemdas?filter=idptsp,=,` +
            this.$route.params.id
        )
        .then((response) => {
          this.profil = response.data.data;
          // localStorage.setItem('idptsp', JSON.stringify(response.data.data.idptsp))
          console.log(response.data.data);
        });
    },
  },
  created() {
    this.loaddata();
  },
};
</script>